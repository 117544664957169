import React, { useRef, useEffect, useState } from 'react';
import  { SlArrowUp, SlArrowDown, SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import style from './style.module.css';

const Swing = ({ children, isHorisontal }) => {
    const listRef = useRef();
    const [scroll, setScroll] = useState(0);
    const [maxScroll, setMaxScroll] = useState(0);

    useEffect(() => {
        listRef.current?.addEventListener('scroll', () => { 
            const { scrollHeight, clientHeight, scrollTop, scrollWidth, clientWidth, scrollLeft } = listRef.current;
            setScroll(Math.abs(isHorisontal ? scrollLeft : scrollTop));
            setMaxScroll((isHorisontal ? (scrollWidth - clientWidth) : (scrollHeight - clientHeight)) - 5);
        });

        return () => listRef.current?.removeEventListener('scroll', () => {});
    }, [isHorisontal]);

    const shift = 60;
    return (
        <>
            <nav className={style.NavArrow} 
                style={{ 
                    [isHorisontal ? 'right': 'top']: '10px', 
                    display: scroll < 1 ? 'none' : 'flex'
                }} 
                onClick={() => isHorisontal ? listRef.current.scrollBy(shift, 0) : listRef.current.scrollBy(0, -shift)}>
                    
                   { isHorisontal ? <SlArrowRight title={scroll}/> : <SlArrowUp /> }
            </nav>
                {children(listRef)}
            <nav className={style.NavArrow} style={{ 
                    [ isHorisontal ? 'left': 'bottom']: '10px', 
                    display: scroll > maxScroll ? 'none' : 'flex'
                }} 
                onClick={() =>  isHorisontal ? listRef.current.scrollBy(-shift, 0) : listRef.current.scrollBy(0, shift)} 
                    >
                    { isHorisontal ? <SlArrowLeft title={maxScroll}/> : <SlArrowDown/> }
            </nav>
        </>
    )
}

export default Swing;